import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Icon, Tooltip } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/tooltip.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Icon: Icon, Tooltip: Tooltip }}
        code={snippet}
        platform="react"
        gitHubLink="tooltip"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="children" types={['node']}>
            <Text>The element contained within the tooltip.</Text>
          </PropListItem>

          <PropListItem name="position" types={['oneOf', 'string']}>
            <Text>
              Where the tooltip will appear in relation to the contained
              element.
            </Text>
            <List type="unordered">
              <li>
                <code>top</code>
              </li>
              <li>
                <code>bottom</code>
              </li>
              <li>
                <code>left</code>
              </li>
              <li>
                <code>right</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="text" types={['string']}>
            <Text>What the tooltip should say.</Text>
          </PropListItem>

          <PropListItem name="parent" types={['string']}>
            <Text>
              If you’re placing a tooltip in an absolutely positioned container,
              pass the id here to correctly position the tooltip itself.
            </Text>
          </PropListItem>

          <PropListItem name="delayTime" types={['number']}>
            <Text>The wait before a tooltip appears.</Text>
          </PropListItem>

          <PropListItem name="maxWidth" types={['string']}>
            <Text>
              Changes the max. width of the tooltip from the default 11.25rem.
            </Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this tooltip in automated tests.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
